import React from 'react';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { BusinessHours } from 'server/src/app/opening/types';
import useFormattedHours from 'hooks/useFormattedHours';

const SEO_QUERY = graphql`
  query SEOQuery {
    site {
      siteMetadata {
        address1
        address2
        author
        city
        description
        email
        facebookUrl
        instagramUrl
        logoUrl
        menuUrl
        postalCode
        reservationUrl
        siteUrl
        telephoneLocal
        title
        tripAdvisorUrl
        vat
        yelpUrl
      }
    }
    dineInHours: hours(id: { eq: "dineIn" }) {
      monday {
        id
        label
        abbrev
        lunchActive
        lunchFrom
        lunchTo
        dinnerActive
        dinnerFrom
        dinnerTo
        position
      }
      tuesday {
        id
        label
        abbrev
        lunchActive
        lunchFrom
        lunchTo
        dinnerActive
        dinnerFrom
        dinnerTo
        position
      }
      wednesday {
        id
        label
        abbrev
        lunchActive
        lunchFrom
        lunchTo
        dinnerActive
        dinnerFrom
        dinnerTo
        position
      }
      thursday {
        id
        label
        abbrev
        lunchActive
        lunchFrom
        lunchTo
        dinnerActive
        dinnerFrom
        dinnerTo
        position
      }
      friday {
        id
        label
        abbrev
        lunchActive
        lunchFrom
        lunchTo
        dinnerActive
        dinnerFrom
        dinnerTo
        position
      }
      saturday {
        id
        label
        abbrev
        lunchActive
        lunchFrom
        lunchTo
        dinnerActive
        dinnerFrom
        dinnerTo
        position
      }
      sunday {
        id
        label
        abbrev
        lunchActive
        lunchFrom
        lunchTo
        dinnerActive
        dinnerFrom
        dinnerTo
        position
      }
    }
  }
`;

interface Data {
  site: {
    siteMetadata: {
      address1: string;
      address2: string;
      author: string;
      city: string;
      description: string;
      email: string;
      facebookUrl: string;
      instagramUrl: string;
      logoUrl: string;
      menuUrl: string;
      postalCode: string;
      reservationUrl: string;
      siteUrl: string;
      telephoneLocal: string;
      tripAdvisorUrl: string;
      title: string;
      vat: string;
      yelpUrl: string;
    };
  };
  dineInHours: BusinessHours;
}

export interface Props {
  canonical?: string;
  description?: string;
  lang?: string;
  path: string;
  coverImagePath?: string;
  title?: string;
}

const SEO: React.FC<Props> = ({
  canonical,
  description,
  lang = 'de-DE',
  path,
  coverImagePath,
  title,
}) => {
  const {
    site: { siteMetadata },
    dineInHours,
  } = useStaticQuery<Data>(SEO_QUERY);

  const metaTitle = `${
    title ? `${title} @ ` : ''
  }THAMARAI Restaurant Heilbronn • Küche aus Sri Lanka und Indien`;
  const metaDescription = description || siteMetadata.description;

  const formattedHours = useFormattedHours(dineInHours);
  let schemaOrgHours: string[] = [];
  formattedHours
    .filter(hour => !hour.val.includes('geschlossen'))
    .forEach(hour => {
      hour.val.forEach(time => {
        schemaOrgHours.push(
          `${hour.key
            .replace('Di', 'Tu')
            .replace('Mi', 'We')
            .replace('Do', 'Th')
            .replace('So', 'Su')} ${time}`
        );
      });
    });

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={`%s`}
    >
      <meta name="description" content={metaDescription} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <meta name="og:type" content={`article`} />
      <meta name="og:url" content={`${siteMetadata.siteUrl}${path}`} />
      <meta name="og:locale" content={lang.replace('-', '_')} />
      <meta
        name="og:site_name"
        content={`${siteMetadata.title} ${siteMetadata.city}`}
      />
      <meta name="og:title" content={metaTitle} />
      <meta name="og:description" content={metaDescription} />
      {coverImagePath && (
        <meta
          name="og:image"
          content={`${siteMetadata.siteUrl}${coverImagePath}`}
        />
      )}
      <meta
        name="article:publisher"
        content={`https://www.facebook.com/thamarai.restaurant/`}
      />
      <meta name="twitter:card" content={`summary_large_image`} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:title" content={metaTitle} />

      <meta name="robots" content="index, follow" />
      <meta
        name="googlebot"
        content={`index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1`}
      />
      <meta
        name="bingbot"
        content={`index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1`}
      />
      <link rel="profile" href="https://gmpg.org/xfn/11" />
      {canonical && (
        <link rel="canonical" href={`${siteMetadata.siteUrl}${canonical}`} />
      )}
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "Restaurant",
          "url": "${siteMetadata.siteUrl}",
          "name": "${siteMetadata.title} ${siteMetadata.city}",
          "telephone": "${siteMetadata.telephoneLocal}",
          "email": "${siteMetadata.email}",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "${siteMetadata.city}",
            "addressRegion": "Baden-Württemberg",
            "postalCode": "${siteMetadata.postalCode}",
            "streetAddress": "${siteMetadata.address1}"
          },
          "servesCuisine": [
            "Indian",
            "Sri Lankan",
            "Tamil"
          ],
          "priceRange": "$$",
          "openingHours": [${schemaOrgHours
            .map(hour => `"${hour}"`)
            .join(', ')}],
          "acceptsReservations": "${siteMetadata.reservationUrl}",
          "hasMenu": "${siteMetadata.menuUrl}",
          "paymentAccepted": [
            "Cash",
            "Credit Card",
            "Cryptocurrency"
          ],
          "vatID": "${siteMetadata.vat}",
          "sameAs" : [
            "${siteMetadata.instagramUrl}",
            "${siteMetadata.facebookUrl}",
            "${siteMetadata.tripAdvisorUrl}",
            "${siteMetadata.yelpUrl}"
          ],
          "logo": "${siteMetadata.logoUrl}",
          "image": "https://www.thamarai.de/thamarai-interior.jpg"
        }
      `}
      </script>
    </Helmet>
  );
};

export default SEO;
