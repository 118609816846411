import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img, { GatsbyImageFluidProps } from 'gatsby-image';

import { FooterAside, FooterMain, FooterBottom } from './components';
import { BusinessHours } from 'server/src/app/opening/types';
import useFormattedHours from 'hooks/useFormattedHours';
import styles from './Footer.module.scss';

const FOOTER_QUERY = graphql`
  query FooterQuery {
    site {
      siteMetadata {
        title
        telephone
        email
        vat
      }
    }
    dineInHours: hours(id: { eq: "dineIn" }) {
      monday {
        id
        label
        abbrev
        lunchActive
        lunchFrom
        lunchTo
        dinnerActive
        dinnerFrom
        dinnerTo
        position
      }
      tuesday {
        id
        label
        abbrev
        lunchActive
        lunchFrom
        lunchTo
        dinnerActive
        dinnerFrom
        dinnerTo
        position
      }
      wednesday {
        id
        label
        abbrev
        lunchActive
        lunchFrom
        lunchTo
        dinnerActive
        dinnerFrom
        dinnerTo
        position
      }
      thursday {
        id
        label
        abbrev
        lunchActive
        lunchFrom
        lunchTo
        dinnerActive
        dinnerFrom
        dinnerTo
        position
      }
      friday {
        id
        label
        abbrev
        lunchActive
        lunchFrom
        lunchTo
        dinnerActive
        dinnerFrom
        dinnerTo
        position
      }
      saturday {
        id
        label
        abbrev
        lunchActive
        lunchFrom
        lunchTo
        dinnerActive
        dinnerFrom
        dinnerTo
        position
      }
      sunday {
        id
        label
        abbrev
        lunchActive
        lunchFrom
        lunchTo
        dinnerActive
        dinnerFrom
        dinnerTo
        position
      }
    }
    image: file(relativePath: { eq: "mangolassi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 385, maxHeight: 520) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

interface Data {
  site: {
    siteMetadata: {
      title: string;
      telephone: string;
      email: string;
      vat: string;
    };
  };
  image: {
    childImageSharp: GatsbyImageFluidProps;
  };
  dineInHours: BusinessHours;
}

interface Props {
  className?: string;
  visible: boolean;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null;
}

const Footer = React.forwardRef<HTMLElement, Props>(
  ({ className, visible, icon }, ref) => {
    const { image, site, dineInHours } = useStaticQuery<Data>(FOOTER_QUERY);
    const formattedHours = useFormattedHours(dineInHours);

    return (
      <footer
        ref={ref}
        className={`${styles[`Footer`]} ${className ? className : ''}`}
      >
        <div
          className={`${styles[`Footer__inner`]} ${
            visible ? styles['Footer__inner--visible'] : ''
          }`}
        >
          <div className={styles[`Footer__body`]}>
            <FooterMain
              className={styles[`Footer__main`]}
              hours={formattedHours}
              title={site.siteMetadata.title}
              telephone={site.siteMetadata.telephone}
              email={site.siteMetadata.email}
              icon={icon}
            />
            <FooterAside className={styles[`Footer__aside`]} />
            <FooterBottom
              title={site.siteMetadata.title}
              vat={site.siteMetadata.vat}
            />
          </div>
        </div>
        <div className={styles[`Footer__image`]}>
          <Img
            className={styles[`Footer__img`]}
            fluid={image.childImageSharp.fluid}
            alt="Hausgemachter Mango Lassi"
            title="Hausgemachter Mango Lassi"
          />
        </div>
      </footer>
    );
  }
);

export default Footer;
