import React from 'react';
import { Link } from 'gatsby';
import styles from './FooterBottom.module.scss';

interface Props {
  title: string;
  vat: string;
}

const FooterBottom = ({ vat }: Props) => {
  return (
    <div className={styles[`FooterBottom`]}>
      <div className={styles[`FooterBottom__inner`]}>
        <div className={styles[`FooterBottom__copyright`]}>
          © {new Date().getFullYear()}
        </div>
        <div className={styles[`FooterBottom__vat`]}>
          <span>USt.-Id:</span>
          <span>{vat}</span>
        </div>
        <div className={styles[`FooterBottom__links`]}>
          <Link className={styles[`FooterBottom__link`]} to="/impressum">
            <span className={styles[`FooterBottom__bullet`]}>
              &#x2022;&nbsp;
            </span>{' '}
            Impressum
          </Link>
          <Link className={styles[`FooterBottom__link`]} to="/datenschutz">
            &#x2022;&nbsp; Datenschutz
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FooterBottom;
