import React, { useEffect, useState, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Hamburger from '@soosap/sushi/Hamburger';

import SEO, { Props as SEOProps } from './components/SEO';
import Header, { Props as HeaderProps } from './components/Header';
import { useKeyboardJs } from 'molecules/Keyboard';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import { Notice } from 'server/src/app/notice/types';
import BananaTreeIcon from 'icons/BananaTree';
import styles from './Layout.module.scss';

const LAYOUT_QUERY = graphql`
  query LayoutQuery {
    notice(id: { eq: "default" }) {
      active
    }
  }
`;

interface Data {
  notice: Notice;
}

interface FC<P = {}> extends React.FC<P> {
  SEO: React.FC<SEOProps>;
  Header: React.FC<HeaderProps>;
}

interface Props {
  children: React.ReactNode;
  searchEngineOptimization?: () => React.ReactElement<SEOProps>;
  className?: string;
  header?: () => React.ReactElement<HeaderProps>;
  footerIcon?: React.FC<React.SVGProps<SVGSVGElement>> | null;
}

const Layout: FC<Props> = ({
  children,
  className,
  header,
  searchEngineOptimization,
  footerIcon = BananaTreeIcon,
}) => {
  const footerEl = useRef<HTMLElement>(null);
  const [footerVisible, setFooterVisible] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const { notice } = useStaticQuery<Data>(LAYOUT_QUERY);

  useEffect(() => {
    const html = document.querySelector('html');
    sidebarOpen
      ? ((html as HTMLHtmlElement).style.overflow = 'hidden')
      : ((html as HTMLHtmlElement).style.overflow = 'visible');
  }, [sidebarOpen]);

  const handleScroll = (): void => {
    if (footerEl && footerEl.current) {
      const footerHeight = footerEl.current.scrollHeight;
      const bodyHeight = document.body.offsetHeight;
      const windowHeight = window.innerHeight;
      let distanceScrolled = window.scrollY;
      const leeway =
        bodyHeight - windowHeight - footerHeight - distanceScrolled;

      if (leeway < 0) {
        setFooterVisible(true);
      }
    }
  };

  useKeyboardJs('esc', () => sidebarOpen && setSidebarOpen(false));

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={styles[`Layout`]}>
      {searchEngineOptimization && searchEngineOptimization()}
      <div className={styles[`Layout__navigation`]}>
        <div
          className={`${styles[`Layout__button`]} ${
            notice.active ? styles[`Layout__button--with-notice`] : ''
          }`}
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          <Hamburger
            className={styles[`Layout__hamburger`]}
            open={sidebarOpen}
          />
        </div>
        <Sidebar open={sidebarOpen} setOpen={setSidebarOpen} />
        <div
          className={`${styles[`Layout__background`]} ${
            sidebarOpen ? styles['Layout__background--open'] : ''
          } ${notice.active ? styles[`Layout__background--with-notice`] : ''}`}
        />
      </div>
      {header && header()}
      <div
        className={`${styles[`Layout__torso`]} ${className ? className : ''}`}
      >
        <main className={styles[`Layout__main`]}>{children}</main>
      </div>
      <Footer
        className={styles[`Layout__footer`]}
        ref={footerEl}
        visible={footerVisible}
        icon={footerIcon}
      />
    </div>
  );
};

Layout.SEO = SEO;
Layout.Header = Header;

export default Layout;
