import React from 'react';
import Icon from '@soosap/sushi/Icon';
import { FormattedHours } from 'hooks/useFormattedHours/useFormattedHours';
import styles from './FooterMain.module.scss';

interface Props {
  className: string;
  title: string;
  telephone: string;
  email: string;
  hours: FormattedHours;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null;
}

const FooterMain = ({
  className,
  telephone,
  email,
  hours,
  icon: FooterIcon,
}: Props) => {
  return (
    <main className={`${styles[`FooterMain`]} ${className}`}>
      <div className={styles[`FooterMain__contact`]}>
        <div className={styles[`FooterMain__title`]}>
          {FooterIcon && (
            <Icon className={styles[`FooterMain__icon`]} svg={FooterIcon} />
          )}
          <span>THAMARAI Restaurant Heilbronn</span>
        </div>
        <div className={styles[`FooterMain__address`]}>
          Horkheimer Straße 29
        </div>
        <div className={styles[`FooterMain__address`]}>D-74081 Heilbronn</div>
        <div className={styles[`FooterMain__telephone`]}>☎️ {telephone}</div>
        <div className={styles[`FooterMain__email`]}>
          <span>✉️</span>
          <a href={`mailto:${email}`}>{email}</a>
        </div>
      </div>
      <div className={styles[`FooterMain__hours`]}>
        <div className={styles[`FooterMain__subtitle`]}>Öffnungszeiten</div>
        <ul>
          {hours.map((hour) => (
            <li key={hour.key}>
              {hour.key}: {hour.val.join(', ')}
            </li>
          ))}
        </ul>
      </div>
    </main>
  );
};

export default FooterMain;
