import { useEffect, useState } from 'react';

export type Handler = (event: KeyboardEvent) => void;

const noop = (): void => {};

const useKeyboardJs = (combination: string, pressFn: Handler = noop, releaseFn: Handler = noop): void => {
	/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
	const [ keyboardJs, setKeyboardJs ] = useState<any>(null);

	// componentDidMount
	useEffect((): void => {
		import('keyboardjs').then(setKeyboardJs);
	}, []);

	// componentDidUpdate
	useEffect((): (() => void) | undefined => {
		if (!keyboardJs) {
			return;
		}

		keyboardJs.bind(combination, pressFn, releaseFn);

		return (): void => {
			keyboardJs.unbind(combination, pressFn, releaseFn);
		};
	});
};

export default useKeyboardJs;
