import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img, { GatsbyImageProps } from 'gatsby-image';
import { Notice } from 'server/src/app/notice/types';
import { ReactComponent as Logo } from '../../../../../images/logos/thamarai-logo-combination.svg';
import styles from './Header.module.scss';

const HEADER_QUERY = graphql`
  query HeaderQuery {
    notice(id: { eq: "default" }) {
      active
      message
    }
  }
`;

interface Data {
  notice: Notice;
}

export interface Props {
  alt?: string;
  abstract?: string;
  className?: string;
  image?: GatsbyImageProps;
  logo?: boolean;
  subtitle?: string;
  title?: string;
  to?: string;
}

const Header: React.FC<Props> = ({
  alt = 'THAMARAI Restaurant Heilbronn Logo',
  abstract,
  className,
  image,
  logo = true,
  subtitle,
  title,
  to,
}) => {
  const { notice } = useStaticQuery<Data>(HEADER_QUERY);

  return (
    <header className={`${styles[`Header`]} ${className ? className : ''}`}>
      {notice.active && (
        <div className={styles['Header__notice']}>
          <div className={styles['Header__notice-inner']}>{notice.message}</div>
        </div>
      )}
      {title && <h1 className={styles[`Header__title`]}>{title}</h1>}
      {subtitle && <h2 className={styles[`Header__subtitle`]}>{subtitle}</h2>}
      {image ? (
        <div className={styles[`Header__image-wrapper`]}>
          {to ? (
            <Link to={to} className={styles[`Header__link`]}>
              <Img className={styles[`Header__image`]} {...image} alt={alt} />
            </Link>
          ) : (
            <Img className={styles[`Header__image`]} {...image} alt={alt} />
          )}
        </div>
      ) : logo ? (
        <div className={styles[`Header__logo`]}>
          <Logo />
        </div>
      ) : null}

      <a
        className={`${styles[`Header__badge`]} ${
          notice.active ? styles[`Header__badge--with-notice`] : ''
        }`}
        href="tel:+4971314051170"
      >
        <div
          className={`${styles[`Header__telephone`]} ${
            styles[`Header__telephone--inner`]
          }`}
        >
          07131
        </div>
        <div
          className={`${styles[`Header__telephone`]} ${
            styles[`Header__telephone--outer`]
          }`}
        >
          405 11 70
        </div>
      </a>
      {abstract && <div className={styles[`Header__abstract`]}>{abstract}</div>}
    </header>
  );
};

export default Header;
