import React from 'react';
import { Link } from 'gatsby';
import { graphql, useStaticQuery } from 'gatsby';
import Icon, { IconSize } from '@soosap/sushi/Icon';

import { ReactComponent as TextLogo } from '../../../../../images/logos/thamarai-logo-text.svg';
import InstagramIcon from 'icons/Instagram';
import FacebookIcon from 'icons/Facebook';
import TripAdvisorIcon from 'icons/TripAdvisor';
import styles from './Sidebar.module.scss';

const SIDEBAR_QUERY = graphql`
  query SidebarQuery {
    site {
      siteMetadata {
        facebookUrl
        instagramUrl
        tripAdvisorUrl
      }
    }
  }
`;

interface Data {
  site: {
    siteMetadata: {
      facebookUrl: string;
      instagramUrl: string;
      tripAdvisorUrl: string;
    };
  };
}

export interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const links = [
  {
    label: `Reservierung`,
    to: `/tisch-reservieren`,
    secondary: false,
    position: 1,
  },
  {
    label: `Speisekarte`,
    to: `/speisekarte-tamilische-spezialitaeten`,
    secondary: false,
    position: 2,
  },
  {
    label: `Bestellen`,
    to: `/bestellen`,
    secondary: false,
    position: 4,
  },
  {
    label: `Über uns`,
    to: `/ueber-uns`,
    secondary: false,
    position: 5,
  },
  // {
  //   label: `Blog`,
  //   to: `/blog`,
  //   secondary: false,
  //   position: 6,
  // },
  {
    label: `Kontakt`,
    to: `/kontakt-aufnehmen`,
    secondary: true,
    position: 1,
  },
  {
    label: `Getränkekarte`,
    to: `/getraenkekarte`,
    secondary: true,
    position: 4,
  },
  {
    label: `Weinkarte`,
    to: `/weine-aus-heilbronn-und-indien`,
    secondary: true,
    position: 3,
  },
  {
    label: `Gutschein`,
    to: `/gutschein-verschenken`,
    secondary: true,
    position: 2,
  },
  // {
  //   label: `Barrierefrei`,
  //   to: `/barrierefrei`,
  //   secondary: true,
  //   position: 3,
  // },
  // {
  //   label: `Catering`,
  //   to: `/catering`,
  //   secondary: true,
  //   position: 5,
  // },
];

const Sidebar: React.FC<Props> = ({ open, setOpen }) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery<Data>(SIDEBAR_QUERY);

  const toggleOpen = () => setOpen(!open);

  return (
    <nav
      className={`${styles[`Sidebar`]} ${open ? styles['Sidebar--open'] : ''}`}
    >
      <div className={styles[`Sidebar__inner`]}>
        <div className={styles[`Sidebar__logo`]}>
          <Link to={`/`} onClick={() => setOpen(!open)}>
            <TextLogo />
          </Link>
        </div>
        <ul
          className={`${styles[`Sidebar__list`]} ${
            styles[`Sidebar__list--primary`]
          }`}
        >
          {links
            .filter(l => l.secondary === false)
            .sort((a, b) => a.position - b.position)
            .map(l => (
              <li key={l.label} className={styles[`Sidebar__item`]}>
                <Link
                  className={styles[`Sidebar__link`]}
                  activeClassName={styles[`Sidebar__link--active`]}
                  to={l.to}
                  onClick={toggleOpen}
                >
                  {l.label}
                </Link>
              </li>
            ))}
        </ul>
        <ul
          className={`${styles[`Sidebar__list`]} ${
            styles[`Sidebar__list--secondary`]
          }`}
        >
          {links
            .filter(l => l.secondary === true)
            .sort((a, b) => a.position - b.position)
            .map(l => (
              <li
                key={l.label}
                className={`${styles[`Sidebar__item`]} ${
                  l.secondary ? styles['Sidebar__item--secondary'] : ''
                }`}
              >
                <Link
                  className={`${styles[`Sidebar__link`]} ${
                    l.secondary ? styles['Sidebar__link--secondary'] : ''
                  }`}
                  activeClassName={styles[`Sidebar__link--active`]}
                  onClick={toggleOpen}
                  to={l.to}
                >
                  {l.label}
                </Link>
              </li>
            ))}
        </ul>
        <ul className={styles[`Sidebar__social`]}>
          <li className={styles[`Sidebar__social-icon`]}>
            <a href={siteMetadata.instagramUrl}>
              <Icon svg={InstagramIcon} />
            </a>
          </li>
          <li className={styles[`Sidebar__social-icon`]}>
            <a href={siteMetadata.facebookUrl}>
              <Icon svg={FacebookIcon} />
            </a>
          </li>
          <li className={styles[`Sidebar__social-icon`]}>
            <a href={siteMetadata.tripAdvisorUrl}>
              <Icon svg={TripAdvisorIcon} size={IconSize.MEDIUM} />
            </a>
          </li>
        </ul>
        <ul className={styles[`Sidebar__legal`]}>
          <li className={styles[`Sidebar__legal-link`]}>
            <Link to={`/impressum`} onClick={toggleOpen}>
              Impressum
            </Link>
          </li>
          <li className={styles[`Sidebar__legal-link`]}>
            <Link to={`/datenschutz`} onClick={toggleOpen}>
              Datenschutz
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
