import React from 'react';
import { Link } from 'gatsby';
import styles from './FooterAside.module.scss';

interface Props {
  className: string;
}

const FooterAside = ({ className }: Props) => {
  return (
    <nav className={`${styles[`FooterAside`]} ${className}`}>
      <ol className={styles[`FooterAside__menu`]}>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/ueber-uns">Über uns</Link>
        </li>
        <li>
          <Link to="/speisekarte-tamilische-spezialitaeten">Speisekarte</Link>
        </li>
        <li>
          <Link to="/tisch-reservieren">Reservieren</Link>
        </li>
        <li>
          <Link to="/gutschein-verschenken">Gutschein</Link>
        </li>
        <li>
          <Link to="/bestellen">Bestellen</Link>
        </li>
      </ol>
    </nav>
  );
};

export default FooterAside;
